import * as React from "react"

const Layout = ({ pageTitle, children }) => {


  return (
    <div className="w-full bg-black px-4 md:px-8">
      <main className="font-sans">
        {children}
      </main>
    </div>
  )
}

export default Layout
